application.register("part_item_counters", class extends Stimulus.Controller {
    connect() {
        let self = this, element = self.element;

        if(!document.documentElement.classList.contains("ie")) {
            let callback = function(mutationsList, observer) {
                for(let mutation of mutationsList) {
                    if(element.classList.contains("is-inview")) {
                        observer.disconnect();
                        self.runCounter();
                    }
                }
            };
            let observer = new MutationObserver(callback);
            observer.observe(element, { attributes: true, attributeFilter: ['class'] });
        }
        else {
            self.runCounter();
        }
    }
    runCounter() {
        let self = this, element = self.element;
        importScript(cdnjs.countup,()=> {
            let number = element.querySelector("[data-value]"),
                value = number.dataset["value"],
                decimals = value.split(".");
            if(decimals[1] !== undefined) {
                decimals = decimals[1].length
            }
            else {
                decimals = 0
            }
            let options = {
                separator: " ",
                decimal: ',',
            };
            let counter = new CountUp(number,0,parseFloat(value),decimals, 2,options);
            if (!counter.error) {
                setTimeout(()=>{
                    counter.start();
                },500)
            } else {
                console.error(counter.error);
            }
        });
    }
});
