application.register("lib-reveal", class extends Stimulus.Controller {
    connect() {
        this.setReveals()

        swup.on('contentReplaced', () => {
            this.setReveals()
        })
    }

    setReveals() {
        const revealElements = [...this.element.querySelectorAll('[data-scroll]')]

        if (revealElements[0] !== null) {
            revealElements.forEach(element => {
                inView(element, {
                    rootMargin: '0px'
                }).then(() => {
                    element.classList.add('is-inview')
                })
            })
        }
    }
});
