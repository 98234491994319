application.register("comp_chart", class extends Stimulus.Controller {
    get ref() {
        return {
            chart: this.targets.find("chart")
        }
    }

    connect() {
        let self = this, element = self.element;

        if(!document.documentElement.classList.contains("ie")) {
            let callback = function(mutationsList, observer) {
                for(let mutation of mutationsList) {
                    if(element.classList.contains("is-inview")) {
                        observer.disconnect();
                        self.generateChart();
                    }
                }
            };
            let observer = new MutationObserver(callback);
            observer.observe(element, { attributes: true, attributeFilter: ['class'] });
        }
        else {
            self.generateChart();
        }
    }

    getData(url, callback) {
        if (typeof lui_references_responses[url] === "undefined") {
            fetch(url, {headers: {'X-Requested-With': 'XMLHttpRequest'}})
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    window.lui_references_responses[url] = data;
                    callback(data);
                })
        }
        else {
            callback(window.lui_references_responses[url]);
        }
    }
    generateChart() {
        this.getData(this.ref.chart.dataset["chart"],(data)=>{
            importStyle(cdnjs.chartist_css);
            importScript(cdnjs.chartist,()=>{
                new Chartist.Bar(this.ref.chart, {
                    labels: data["labels"],
                    series: data["series"]
                }, {
                    stackBars: true,
                    fullWidth: true,
                    chartPadding: 40,
                    axisX: {
                        showGrid: false,
                        showLabel: true,
                    },
                    axisY: {
                        showGrid: false,
                        showLabel: false,
                        offset: 0
                    }
                }).on('draw', function(elm) {
                    if(elm.type === "label") {
                        if(!document.documentElement.classList.contains("ie")) {
                            let label = elm.element.getNode();
                            let parser = new DOMParser();
                            let content = parser.parseFromString(`<small>${data.series[1].data[elm.index]}&nbsp;${data.series[1].unit}</small>`,"text/html").querySelector("small");
                            label.querySelector("span").appendChild(content);
                        }
                    }
                    if(elm.type === "bar") {
                        let bar = elm.element.getNode();
                        bar.setAttribute("data-controller","lib-tippy");
                        bar.setAttribute("data-lib-tippy-options","tooltip-top");
                        bar.setAttribute("aria-label",`
                            <strong>${data.series[0].name}:&nbsp;${data.series[0].data[elm.index]}&nbsp;${data.series[0].unit}</strong>
                            <br />
                            <strong>${data.series[1].name}:&nbsp;${data.series[1].data[elm.index]}&nbsp;${data.series[1].unit}</strong>
                        `);

                        let duration = 1000;
                        elm.element.animate({
                            y2: {
                                begin: duration*elm.seriesIndex + duration/10*elm.index,
                                dur: duration,
                                from: elm.y1,
                                to: elm.y2
                            }
                        });
                    }
                });
            });
        });
    }
});
