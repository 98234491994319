const LibTippy = function (element, options) {
    let data = options.replace(/\s/g,"").split(",");
    let content;
    let trigger = "mouseenter focus";
    let placement = "top";
    let arrow = true;
    let cursor = false;
    let distance = 10;
    let interactive = true;

    if (typeof data[1] !== "undefined") {
        content = document.getElementById(data[1]).content.childNodes[0];
    } else {
        let parser = new DOMParser();
        content = parser.parseFromString(`
                <div class="part_ui_dropdown">
                    <div class="wrp_ui_body">
                        ${element.getAttribute("aria-label")}
                    </div>
                </div>
            `,"text/html").querySelector(".part_ui_dropdown");
        interactive = false;
    }

    if (typeof data[0] !== "undefined") {
        if (data[0].includes("dropdown")) {
            trigger = "click";
            if (data[0].includes("dropdown-")) {
                placement = data[0].replace("dropdown-", "").replace(/\d+/,"");
                if (data[0].match(/\d+/)) {
                    distance = parseInt(data[0].match(/\d+/)[0]);
                }
            }
        } else if (data[0].includes("tooltip")) {
            if (data[0].includes("tooltip-")) {
                placement = data[0].replace("tooltip-", "");
                arrow = true;
                interactive = false;
            }
        } else {
            placement = data[0];
        }
    }

    tippy(element, {
        content: content,
        placement: placement,
        theme: 'light-border',
        trigger: trigger,
        interactive: interactive,
        arrow: arrow,
        animation: "scale",
        followCursor: cursor,
        flip: true,
        inertia: true,
        distance: distance,
    });
};
