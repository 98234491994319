if (typeof Swup !== "undefined") {
    swup = new Swup({
        containers: ['#layout_main', '#layout_header', '#layout_footer']
    });

    swup.on('clickLink', function () {
        document.body.classList.remove("overflow--hidden");

        application["getControllerForElementAndIdentifier"](document.body, "lib-drawer").hide();

        if (document.querySelector(".lib--dialog") !== null) {
            LibDialog.close();
        }
    });

    swup.on('contentReplaced', function () {
        document.getElementById('layout').classList.remove('is--green','is--orange','is--brown','is--blue','is--purple','is--yellow','is--pink')
        if(typeof document.getElementById('layout_main').dataset.color !== 'undefined') {
            document.getElementById('layout').classList.add(document.getElementById('layout_main').dataset.color)
        }
        if(typeof locomotive !== "undefined") {
            locomotive.destroy();
            document.getElementById("layout_scroll").removeAttribute("style");
            locomotive.init();
        }
        else {
            window.scrollTo(0, 0);
        }

        swup.options.containers.forEach((selector) => {
            loadStimulus(document.querySelector(selector));
        });

        LibAnchor.init();

        if (typeof fbq !== "undefined") {
            fbq('track', 'PageView');
        }

        if (typeof gtag !== "undefined") {
            let configs = [];
            window.dataLayer.forEach(function (config) {
                if (config[0] === "config") {
                    if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                        configs.push(config[1]);
                        gtag('config', config[1], {
                            'page_title': document.title,
                            'page_path': window.location.pathname + window.location.search
                        });
                    }
                }
            });
        }

        if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({
                'event': 'VirtualPageview',
                'virtualPageURL': window.location.pathname + window.location.search,
                'virtualPageTitle': document.title
            });
        }

        if (typeof window.seznam_retargeting_id !== "undefined") {
            importScript("https://c.imedia.cz/js/retargeting.js");
        }

        if (typeof LibCookieConsent !== "undefined") {
            LibCookieConsent.init()
        }

        document.body.classList.remove("body--preload");
        document.body.classList.add("body--loaded");
    });
}
