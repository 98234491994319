application.register("part_ui_video", class extends Stimulus.Controller {
    connect() {
        let element = this.element, video = element.querySelector("video");
        video.addEventListener("ended",()=>{
            element.classList.remove("state--playing");
            video.currentTime = 0;
            video.removeAttribute("controls");
        });

        element.insertAdjacentHTML('beforeend','<span class="icon--play"></span>');
    }
    togglePlay(e) {
        e.preventDefault();
        e.stopPropagation();

        let element = this.element, video = element.querySelector("video");

        if(video.paused) {
            video.play();
            element.classList.add("state--playing");
        }
        else {
            video.pause();
            element.classList.remove("state--playing");
        }
    }
});
