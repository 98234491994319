const LibMasonry = function (element, options) {
    importScript(cdnjs.masonry,function () {
        cssLoaded(function () {
            let msnry;
            let horizontalOrder = (typeof options["horizontalOrder"] !== "undefined") ? options["horizontalOrder"] : true;
            let percentPosition = (typeof options["percentPosition"] !== "undefined") ? options["percentPosition"] : true;

            if (typeof options["horizontalOrder"] === "undefined") {
                if(window.innerWidth <= 960) {
                    horizontalOrder = false;
                }
            }

            if (!Masonry.data(element)) {
                msnry = new Masonry(element,{
                    itemSelector: (typeof options["itemSelector"] !== "undefined") ? options["itemSelector"] : ".col",
                    percentPosition: percentPosition,
                    columnWidth: (typeof options["columnWidth"] !== "undefined") ? options["columnWidth"] : ".col",
                    horizontalOrder: horizontalOrder,
                    fitWidth: false
                });
            } else {
                msnry.appended(element.querySelectorAll(`.col--masonry:not([style])`));
                msnry.layout();
            }

            window.addEventListener("resize", debounce(function () {
                msnry.layout();

                if(typeof locomotive !== "undefined") {
                    let scrollPosition = locomotive.scroll.instance.delta.y;
                    locomotive.update();
                    locomotive.scrollTo(scrollPosition);
                }
            },150));
        });
    });
};
